<template>
  <div class="echartsContent">
    <div style="text-align: center;">
      <el-radio-group v-model="runAcount">
        <el-radio-button label="运行次数"></el-radio-button>
        <el-radio-button label="检修次数"></el-radio-button>
      </el-radio-group>
    </div>
    <div style="flex: 1;" v-loading="loading">
      <div id="echartOne" style="width: 100%; height: 100%;"></div>
    </div>
  </div>
</template>


<script>
export default {
  data() {
    return {
      runAcount: "运行次数",
      runTimesData: [],
      repairTimesData: [],
      loading: false,
      myChart: null,
      show: false,
      show1: false
    };
  },

  props: {
    id: {
      type: [Number, String]
    }
  },

  watch: {
    id: {
      handler(value) {
        if (value) {
          this.getRunTimesByElevatorId(value);
          this.getRepairTimesByElevatorId(value);
        }
      }
    },
    runAcount: {
      handler(value) {
        if (this.runAcount === "检修次数") {
          console.log("检修次数");
          this.initCharts2();
        } else {
          this.initCharts();
        }
      },
    }
  },

  methods: {
    // 根据电梯id查询近七天运行次数
    getRunTimesByElevatorId(id) {
      this.loading = true;
      this.$http.get(`singleLadderMonitor/count/run/${id}`).then(res => {
        this.runTimesData = res.data;
        let rsOne = this.runTimesData[1].some(item => item);
        if (rsOne) {
          this.show = true;
        } else {
          this.show = false;
        }
        this.loading = false;
        this.initCharts();
      });
    },

    // 根据电梯id查询近七天检修次数
    getRepairTimesByElevatorId(id) {
      this.$http.get(`singleLadderMonitor/count/repair/${id}`).then(res => {
        this.repairTimesData = res.data;
        let rsOne = this.repairTimesData[1].some(item => item);
        if (rsOne) {
          this.show1 = true;
        } else {
          this.show1 = false;
        }
      });
    },

    initCharts() {
      if (this.myChart) {
        this.myChart.dispose();
      }
      this.myChart = this.$echarts.init(document.getElementById("echartOne"));
      let option = {
        tooltip: {},
        grid: {
          left: "3%",
          right: "3%",
          bottom: "3%",
          top: "20%",
          containLabel: true
        },
        xAxis: {
          show: this.show,
          type: "category",
          data: this.runTimesData[0]
        },
        yAxis: {
          show: this.show,
          type: "value",
          name: "单位：次",
        },
        series: [
          {
            data: this.runTimesData[1],
            type: "bar",
            barWidth: 15,
            itemStyle: {
              normal: {
                color: "#0747fd",
              }
            },
          }
        ],
        graphic: {
          type: "text",
          left: "center",
          top: "middle",
          silent: true,
          invisible: this.show,
          style: {
            fill: "#909399",
            text: "暂无数据",
            font: "14px 'Fira Sans', sans-serif"
          }
        }
      };
      this.myChart.setOption(option);
    },

    initCharts2() {
      if (this.myChart) {
        this.myChart.dispose();
      }
      this.myChart = this.$echarts.init(document.getElementById("echartOne"));
      let option = {
        tooltip: {},
        grid: {
          left: "3%",
          right: "3%",
          bottom: "3%",
          top: "20%",
          containLabel: true
        },
        xAxis: {
          show: this.show1,
          type: "category",
          data: this.runTimesData[0]
        },
        yAxis: {
          show: this.show1,
          type: "value",
          name: "单位：次",
        },
        series: [
          {
            data: this.repairTimesData[1],
            type: "bar",
            barWidth: 15,
            itemStyle: {
              normal: {
                color: "#0747fd",
              }
            },
          }
        ],
        graphic: {
          type: "text",
          left: "center",
          top: "middle",
          silent: true,
          invisible: this.show1,
          style: {
            fill: "#909399",
            text: "暂无数据",
            font: "14px 'Fira Sans', sans-serif"
          }
        }
      };
      this.myChart.setOption(option);
    },
  },
};
</script>

<style lang="scss" scoped>
.echartsContent {
  height: 100%;
  display: flex;
  flex-direction: column;
}
</style>