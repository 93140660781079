<template>
  <div class="echartsContent">
    <div style="text-align: center;">
      <el-radio-group v-model="attention">
        <el-radio-button label="故障"></el-radio-button>
        <el-radio-button label="告警"></el-radio-button>
      </el-radio-group>
    </div>
    <div style="flex: 1;" v-loading="loading">
      <div id="echartTwo" style="width: 100%; height: 100%;"></div>
    </div>
  </div>
</template>


<script>
export default {
  data() {
    return {
      attention: "故障",
      faultTypeData: [],
      alarmTimesData: [],
      loading: false,
      myChart: null,
      show: false
    };
  },

  props: {
    id: {
      type: [Number, String]
    }
  },

  watch: {
    id: {
      handler(value) {
        if (value) {
          this.getFaultTypeByElevatorId(value);
          this.getAlarmTimesByElevatorId(value);
        }
      }
    },
    attention: {
      handler(value) {
        if (this.attention === "告警") {
          this.initCharts2();
        } else {
          this.initCharts();
        }
      },
    }
  },

  methods: {
    // 根据电梯id查询近七天故障
    getFaultTypeByElevatorId(id) {
      this.loading = true;
      this.$http.get(`singleLadderMonitor/count/faultType/${id}`).then(res => {
        this.faultTypeData = res.data;
        this.initCharts();
        this.loading = false;
      });
    },

    // 根据电梯id查询近七天告警次数
    getAlarmTimesByElevatorId(id) {
      this.$http.get(`singleLadderMonitor/count/workOrder/${id}`).then(res => {
        this.alarmTimesData = res.data;
        let rsOne = this.alarmTimesData[1].some(item => item);
        let rsTwo = this.alarmTimesData[2].some(item => item);
        if (rsOne || rsTwo) {
          this.show = true;
        } else {
          this.show = false;
        }
      });
    },

    initCharts() {
      if (this.myChart) {
        this.myChart.dispose();
      }
      this.myChart = this.$echarts.init(document.getElementById("echartTwo"));
      let option = {
        // legend: {
        //   bottom: 0,
        //   left: "center",
        // },
        tooltip: {
          trigger: "item",
          formatter: "{b}</br> {c}次({d}%)",
          position: [10, 10],
        },
        color: ["#0747FD", "#7194FC ", "#84D6FF", "#00D09C", "#FDBD50", "#FF7E48"],
        series: [
          {
            type: "pie",
            radius: ["40%", "55%"],
            label: {
              formatter: "{c}次",
            },
            data: this.faultTypeData
          }
        ],
        graphic: {
          type: "text",
          left: "center",
          top: "middle",
          silent: true,
          invisible: this.faultTypeData.length,
          style: {
            fill: "#909399",
            text: "暂无数据",
            font: "14px 'Fira Sans', sans-serif"
          }
        }
      };
      this.myChart.setOption(option);

    },

    initCharts2() {
      if (this.myChart) {
        this.myChart.dispose();
      }
      this.myChart = this.$echarts.init(document.getElementById("echartTwo"));
      let option = {
        tooltip: {},
        grid: {
          left: "3%",
          right: "3%",
          bottom: "3%",
          top: "20%",
          containLabel: true
        },
        xAxis: {
          show: this.show,
          type: "category",
          data: this.alarmTimesData[0]
        },
        yAxis: {
          show: this.show,
          type: "value",
          name: "单位：次",
        },
        series: [
          {
            name: "困人",
            type: "bar",
            barWidth: 15,
            itemStyle: {
              color: "#FF7E48",
            },
            data: this.alarmTimesData[1],
          },
          {
            name: "停梯",
            type: "bar",
            barWidth: 15,
            itemStyle: {
              color: "#0747FD",
            },
            data: this.alarmTimesData[2],
          },
        ],
        graphic: {
          type: "text",
          left: "center",
          top: "middle",
          silent: true,
          invisible: this.show,
          style: {
            fill: "#909399",
            text: "暂无数据",
            font: "14px 'Fira Sans', sans-serif"
          }
        }
      };
      this.myChart.setOption(option);
    },
  },
};
</script>

<style lang="scss" scoped>
.echartsContent {
  height: 100%;
  display: flex;
  flex-direction: column;
}
</style>