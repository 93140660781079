<template>
  <div v-loading="loading" style="height: 100%;">
    <!-- 发布前v-if条件要互换 -->
    <div v-if="gateWayType === 1" style="height: 100%;">
      <lift-monitor v-if="elevatorType === 1 && !futi" ref="monitor"></lift-monitor>
      <lift-monitor-old v-else-if="elevatorType === 1 && !futi" ref="monitor"></lift-monitor-old>
      <escalator-monitor v-else-if="elevatorType === 1 && futi" ref="monitor"></escalator-monitor>
      <lift-sensor-monitor v-else-if="(elevatorType === 2 || elevatorType === 3) && !futi"
        ref="monitor"></lift-sensor-monitor>
      <escalator-sensor-monitor v-else-if="(elevatorType === 2 || elevatorType === 3) && futi"
        ref="monitor"></escalator-sensor-monitor>
      <lift-monitor v-if="elevatorType === 4 && !futi" ref="monitor"></lift-monitor>
    </div>
    <div v-if="gateWayType === 3" style="height: 100%;">
      <LiftMonitorYXD ref="monitor"></LiftMonitorYXD>
    </div>
  </div>
</template>
<script>
import LiftMonitor from "./LiftMonitor.vue";
import LiftMonitorOld from "./LiftMonitorOld.vue";
import EscalatorMonitor from "./EscalatorMonitor.vue";
import LiftSensorMonitor from "./sensor/LiftSensorMonitor.vue";
import EscalatorSensorMonitor from "./sensor/EscalatorSensorMonitor.vue";
import LiftMonitorYXD from "./LiftMonitorYXD.vue";

export default {
  components: { EscalatorMonitor, LiftMonitor, LiftSensorMonitor, EscalatorSensorMonitor, LiftMonitorYXD,LiftMonitorOld },
  data() {
    return {
      loading: true,
      futi: false,
      elevatorType: 0,//1升降梯、协议，2扶梯，3升降梯、传感器，4扶梯、传感器
      gateWayType: 1,
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.$api.getById("singleLadderMonitor", this.$route.params.elevatorId).then(res => {
        this.loading = false;
        let elevator = res.data.elevator;
        let typeCode = elevator.elevatorProductTypeCode;
        this.elevatorType = elevator.monitorType || 1;
        this.gateWayType = elevator.gateWayType;
        if (this.elevatorType == 3) {
          this.elevatorType = 1;
        }
        if (typeCode === "3310" || typeCode === "3320") {
          this.futi = true;
        }
        this.$nextTick(() => {
          this.$refs.monitor.init(res.data);
        });
      }).catch((e) => {
        this.loading = false;
        console.error(e);
        this.$message.error("获取数据失败");
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>
